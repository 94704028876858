
  import { Component, Vue } from "vue-property-decorator";
  import {
    IDirectoryEntry,
    IUserProfileCardUpdate,
    IUserProfileUpdate,
    SearchableField,
  } from "@/interfaces";
  import { readUserProfile } from "@/store/main/getters";
  import {
    dispatchGetUserProfile,
    dispatchRequestNameChange,
    dispatchUpdateUserAvatar,
    dispatchUpdateUserProfile,
    dispatchUpdateUserProfileCard,
  } from "@/store/main/actions";
  import { required, email } from "vee-validate/dist/rules";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import DirectoryEntry from "@/components/DirectoryEntry.vue";
  import countries from "@/countries";
  import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";

  // register validation rules
  extend("required", { ...required, message: "{_field_} can not be empty" });
  extend("email", { ...email, message: "Invalid email address" });

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
      DirectoryEntry,
      VueTelInputVuetify,
    },
  })
  export default class UserProfileEdit extends Vue {
    $refs!: {
      observer: InstanceType<typeof ValidationObserver>;
    };

    countries = countries;

    public valid = true;
    public firstName = "";
    public lastName = "";
    public email = "";
    public phone = "";
    public placeOfEmployment = "";
    public town = "";
    public province = "";
    public country = "";
    public isSearchable = false;
    public companySearchable = false;
    public phoneSearchable = false;
    public emailSearchable = false;
    public locationSearchable = false;
    dialog = false;
    avatarDialog = false;
    file: File | null = null;
    imagePreview = "";
    new_first_name = "";
    new_last_name = "";
    phoneDetail = {
      number: "",
      valid: false,
      country: undefined,
    };

    public async created() {
      await dispatchGetUserProfile(this.$store);
      const userProfile = readUserProfile(this.$store);
      if (userProfile) {
        this.firstName = userProfile.first_name;
        this.lastName = userProfile.last_name;
        this.email = userProfile.email;
        this.phone = userProfile.phone;
        this.town = userProfile.town;
        this.province = userProfile.province;
        this.country = userProfile.country;
        this.placeOfEmployment = userProfile.place_of_employment;
        this.isSearchable = userProfile.is_searchable;
        const searchFields = userProfile.search_fields || [];
        this.companySearchable = searchFields.includes(
          SearchableField.place_of_employment,
        );
        this.phoneSearchable = searchFields.includes(SearchableField.phone);
        this.emailSearchable = searchFields.includes(SearchableField.email);
        this.locationSearchable = searchFields.includes(SearchableField.address);
      }
    }

    get userProfile() {
      return readUserProfile(this.$store);
    }

    formatName(firstName?: string, lastName?: string): string {
      if (!firstName && !lastName) {
        return "-----";
      } else {
        return `${firstName || ""} ${lastName || ""}`;
      }
    }

    formatAddress(country?: string, province?: string, town?: string): string {
      var address = "";
      if (town) {
        address += `${town}, `;
      }
      if (province) {
        address += `${province}, `;
      }
      if (country) {
        address += country;
      }
      return address;
    }

    get fullName() {
      return this.formatName(this.firstName, this.lastName);
    }

    get initials() {
      const firstInitial =
        this.firstName && this.firstName.length > 0 ? this.firstName[0] : "";
      const lastInitial =
        this.lastName && this.lastName.length > 0 ? this.lastName[0] : "";
      return `${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;
    }

    get directoryEntry(): IDirectoryEntry {
      const userProfile = readUserProfile(this.$store);
      if (userProfile) {
        return {
          user_id: userProfile.id,
          name: this.formatName(userProfile.first_name, userProfile.last_name),
          email: this.emailSearchable ? userProfile.email : undefined,
          phone: this.phoneSearchable ? userProfile.phone : undefined,
          place_of_employment: this.companySearchable
            ? userProfile.place_of_employment
            : undefined,
          address: this.locationSearchable
            ? this.formatAddress(
                userProfile.country,
                userProfile.province,
                userProfile.town,
              )
            : undefined,
          avatar_url: userProfile.avatar_url,
        };
      } else {
        return {};
      }
    }

    public onReset() {
      const userProfile = readUserProfile(this.$store);
      if (userProfile) {
        this.firstName = userProfile.first_name;
        this.lastName = userProfile.last_name;
        this.email = userProfile.email;
        this.phone = userProfile.phone;
        this.town = userProfile.town;
        this.province = userProfile.province;
        this.country = userProfile.country;
        this.placeOfEmployment = userProfile.place_of_employment;
      }
      this.$refs.observer.reset();
    }

    public cancel() {
      this.$router.back();
    }

    public async onSubmit() {
      const success = await this.$refs.observer.validate();
      if (!success) {
        return;
      }

      const updatedProfile: IUserProfileUpdate = {};
      if (this.email) {
        updatedProfile.email = this.email;
      }
      if (this.phone && this.phoneDetail.number) {
        updatedProfile.phone = this.phoneDetail.number;
      }
      if (this.town) {
        updatedProfile.town = this.town;
      }
      if (this.province) {
        updatedProfile.province = this.province;
      }
      if (this.country) {
        updatedProfile.country = this.country;
      }
      if (this.placeOfEmployment) {
        updatedProfile.place_of_employment = this.placeOfEmployment;
      }
      await dispatchUpdateUserProfile(this.$store, updatedProfile);
    }

    async onRequestNameChange() {
      await dispatchRequestNameChange(this.$store, {
        new_first_name: this.new_first_name,
        new_last_name: this.new_last_name,
      });

      this.dialog = false;
    }

    public async onSubmitProfileCardUpdate() {
      const updatedProfile: IUserProfileCardUpdate = {};
      const fields: SearchableField[] = [SearchableField.name];
      if (this.companySearchable) {
        fields.push(SearchableField.place_of_employment);
      }
      if (this.phoneSearchable) {
        fields.push(SearchableField.phone);
      }
      if (this.emailSearchable) {
        fields.push(SearchableField.email);
      }
      if (this.locationSearchable) {
        fields.push(SearchableField.address);
      }
      updatedProfile.search_fields = fields;
      updatedProfile.is_searchable = this.isSearchable;
      await dispatchUpdateUserProfileCard(this.$store, updatedProfile);
    }

    public onResetProfileCard() {
      const userProfile = readUserProfile(this.$store);
      if (userProfile) {
        this.isSearchable = userProfile.is_searchable;
        const searchFields = userProfile.search_fields || [];
        this.companySearchable = searchFields.includes(
          SearchableField.place_of_employment,
        );
        this.phoneSearchable = searchFields.includes(SearchableField.phone);
        this.emailSearchable = searchFields.includes(SearchableField.email);
        this.locationSearchable = searchFields.includes(SearchableField.address);
      }
    }

    public onFileChange(file) {
      new Promise<string>((res, rej) => {
        if (!file) {
          rej(file);
        }
        const reader = new FileReader();
        reader.onload = (e1) => {
          if (e1.target) {
            res(e1.target.result as string);
          } else {
            rej(e1);
          }
        };
        reader.onerror = (e1) => rej(e1);
        reader.readAsDataURL(file);
      }).then((imagePreview) => {
        this.imagePreview = imagePreview;
      });
    }

    public async onUpdateAvatar() {
      if (this.file) {
        await dispatchUpdateUserAvatar(this.$store, { file: this.file });
        this.avatarDialog = false;
      }
    }

    onPhoneInput(_, { number, valid, country }) {
      this.phoneDetail.number = number.international;
      this.phoneDetail.valid = valid;
      this.phoneDetail.country = country && country.name;
    }
  }
